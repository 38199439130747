<template>
  <div>
    <vx-card title="Explanation" class="mt-10">
      <vs-row>
        <vs-col class="mb-3" vs-w="2" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-chip transparent color="success">
            <vs-avatar
              color="rgb(var(--vs-success))"
              icon="event_available"
            />
            {{$t('Created At')}}
          </vs-chip>
        </vs-col>
        <vs-col class="mb-3" vs-w="2" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-chip transparent color="warning">
            <vs-avatar
              color="rgb(var(--vs-warning))"
              icon="event_available"
            />
            {{$t('Updated At')}}
          </vs-chip>
        </vs-col>
        <vs-col class="mb-3" vs-w="2" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-chip transparent>
            <font-awesome-icon
              class="mr-2"
              color="rgb(var(--vs-success))"
              :icon="['fas', 'edit']"
            />
            {{$t('Edit Account')}}
          </vs-chip>
        </vs-col>
        <vs-col class="mb-3" vs-w="2" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-chip transparent>
            <font-awesome-icon
              class="mr-2"
              color="rgb(var(--vs-danger))"
              :icon="['fas', 'trash-alt']"
            />
            {{$t('Delete Account')}}
          </vs-chip>
        </vs-col>
        <vs-col class="mb-3" vs-w="2" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-chip transparent>
            <font-awesome-icon
              class="mr-2"
              color="rgb(var(--vs-warning))"
              :icon="['fas', 'exchange-alt']"
            />
            {{$t('View All Transaction')}}
          </vs-chip>
        </vs-col>
        <vs-col class="mb-3" vs-w="2" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-chip transparent>
            <font-awesome-icon
              class="mr-2"
              color="rgb(var(--vs-primary))"
              :icon="['fas', 'ellipsis-v']"
            />
            {{$t('More Options')}}
          </vs-chip>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "AccountExplanations",
}

</script>

<style scoped>

</style>
