<template>
  <div>
    <vs-button
    color="primary"
    type="gradient"
    icon-pack="feather"
    icon="icon-plus"
    :to="{name:'CreateAccount'}"
  >{{$t('Create Account')}}</vs-button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "CreateAccount",

  data: () => ({
  }),

}
</script>

<style scoped>

</style>
