<template>
  <div>
    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <vs-button @click="activePrompt = true" class="mr-2"> {{$t('Export')}} </vs-button>

          <create-account></create-account>
        </div>
      </vs-col>
      <vs-prompt :title="$t('Export')" class="export-options" @cancle="clearFields" @accept="exportToExcel"
        :accept-text="$t('Export')" :cancel-text="$t('Cancel')" @close="clearFields" :active.sync="activePrompt">
        <vs-input v-model="fileName" :placeholder="$t('Enter File Name..')" class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex mt-4">
          <span class="mr-2">{{ $t("Cell Auto Width") }}</span>
          <vs-switch v-model="cellAutoWidth">{{
            $t("Cell Auto Width")
          }}</vs-switch>
        </div>
      </vs-prompt>
      <vs-prompt :title="$t('Account Detail')" class="export-options" :accept-text="$t('Okey')"
        :cancel-text="$t('Cancel')" @close="popupData={}" :active.sync="detailPopup">
        {{$t('Email')}} : {{popupData.email}}<br>
        {{$t('Mobile')}} : {{popupData.mobile}}<br>
        {{$t('Last Transaction')}} : {{ popupData.last_transaction_date }} <br>
        {{$t('Created')}} : {{ popupData.created_at }}

      </vs-prompt>

      <!-- <vs-row class="mt-5 mb-5" vs-justify="center">
            <vs-col class="vx-col sm:w-3/12 mr-5">
              <vx-card :title="$t('Total Outcome')">
                <h5> {{ totalOutcome.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }} TL</h5>
              </vx-card>
            </vs-col>
            <vs-col class="vx-col sm:w-3/12 mr-5">
              <vx-card :title="$t('Total Income')">
                <h5> {{ totalIncome.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }} TL </h5>
              </vx-card>
            </vs-col>
            <vs-col class="vx-col sm:w-3/12">
              <vx-card :title="$t('Total Balance')">
                <h5>{{ (totalIncome-totalOutcome  ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }} TL </h5>
              </vx-card>
            </vs-col>
          </vs-row> -->
      <vs-col vs-w="12">
        <div id="data-list-list-view" class="data-list-container">
          <vs-button @click="hideData=!hideData" size="small" color="warning" class="mt-5">
            {{ !hideData ? $t('Show All') : $t('Hide Non Action') }}
          </vs-button>
          <vs-pagination-table model_type="account" :hidden_length="accounts.length" :data="accounts" :table_name="$t('Accounts')">
            <template slot-scope="{ data }">
              <template>

                <vs-th>{{ $t("Account Name") }}</vs-th>

                <vs-th>{{ $t("Income") }}</vs-th>
                <vs-th>{{ $t("Outcome") }}</vs-th>
                <vs-th>{{ $t("Balance") }}</vs-th>
                <vs-th> {{$t("I / O")}} </vs-th>
                <vs-th>{{ $t("Details") }}</vs-th>
                <vs-th>{{ $t("Options") }}</vs-th>
              </template>
              <template>
                <vs-tr :key="index" v-for="(tr, index) in data">

                  <vs-td>
                    <div style="width: max-content">
                      <router-link class="ml-1 mr-1" :to="{
                          name: 'AccountTransactions',
                          params: { accountId: tr.id },
                        }">
                        <span class="ml-2 text-capital">
                          {{
                        tr.first_name
                      }} {{
                        tr.last_name
                      }}</span>
                      </router-link>
                    </div>

                  </vs-td>

                  <vs-td v-bind:style=" tr.income ? 'min-width:150px' : '' ">
                    <font-awesome-icon color="rgb(var(--vs-success))" :icon="['fas', 'arrow-down']" />
                    {{ tr.income }} TL
                  </vs-td>
                  <vs-td v-bind:style=" tr.outcome ? 'min-width:150px' : '' ">
                    <font-awesome-icon color="rgb(var(--vs-danger))" :icon="['fas', 'arrow-up']" />
                    {{ tr.outcome }} TL
                  </vs-td>
                  <vs-td v-bind:style=" tr.balance ? 'min-width:150px' : '' ">

                    {{ tr.balance }} TL
                  </vs-td>
                  <vs-td>

                    <strong v-if="parseInt(tr.balance)===0">
                      -
                    </strong>
                    <strong v-else-if="parseInt(tr.balance) >0">
                      {{$t('O')}}
                    </strong>
                    <strong v-else>
                      {{$t('I')}}
                    </strong>

                  </vs-td>
                  <vs-td>
                    <vs-button size="small" transparent color="primary" @click="detailPopup=true;popupData=tr">
                      {{$t('See Detail')}}

                    </vs-button>
                  </vs-td>
                  <vs-td v-if="false">
                    <vs-chip transparent color="primary" v-if="tr.last_transaction_date">
                      <vs-avatar color="rgb(var(--vs-primary))" icon="event_note" />
                      <vx-tooltip transparent color="primary" :text="tr.last_transaction_date">
                        {{ tr.last_transaction_from }}
                      </vx-tooltip>
                    </vs-chip>
                    <vs-chip transparent color="warning" v-else>
                      {{ $t(" No Transaction Yet") }}
                    </vs-chip>
                  </vs-td>
                  <vs-td v-if="false">
                    <div style="width: max-content">
                      <vs-chip transparent color="success">
                        <vs-avatar color="rgb(var(--vs-success))" icon="event_available" />
                        <vx-tooltip color="success" :text="tr.created_from">
                          {{ tr.created_at }}
                        </vx-tooltip>
                      </vs-chip>
                    </div>
                    <div style="width: max-content">
                      <vs-chip transparent color="warning">
                        <vs-avatar color="rgb(var(--vs-warning))" icon="update" />
                        <vx-tooltip transparent color="warning" :text="tr.updated_from">
                          {{ tr.updated_at }}
                        </vx-tooltip>
                      </vs-chip>
                    </div>
                  </vs-td>
                  <vs-td>
                    <div class="auto-cols-max">
                      <!-- <router-link class="ml-1 mr-1" :to="{
                          name: 'AccountTransactions',
                          params: { accountId: tr.id },
                        }">
                        <font-awesome-icon color="rgb(var(--vs-warning))" :icon="['fas', 'exchange-alt']" />
                      </router-link> -->
                      <router-link class="ml-1 mr-1" :to="{
                          name: 'EditAccount',
                          params: { accountId: tr.id },
                        }">
                        <font-awesome-icon color="rgb(var(--vs-success))" :icon="['fas', 'edit']" />
                      </router-link>
                      <a class="ml-1 mr-1" href="javascript:void(0)" @click="DeleteAccount(tr.id)">
                        <font-awesome-icon color="rgb(var(--vs-danger))" :icon="['fas', 'trash-alt']" />
                      </a>

                      <span class="ml-1 mr-1">
                        <vs-dropdown v-if="false">
                          <a href="#">
                            <font-awesome-icon color="rgb(var(--vs-primary))" :icon="['fas', 'ellipsis-v']" />
                          </a>

                          <vs-dropdown-menu>
                            <vs-dropdown-item>
                              {{ $t("Create Transaction") }}
                            </vs-dropdown-item>

                            <vs-dropdown-item divider>
                              {{ $t("View All Transaction") }}
                            </vs-dropdown-item>
                            <vs-dropdown-item divider>
                              {{ $t("view posting operation") }}
                            </vs-dropdown-item>
                            <vs-dropdown-item divider>
                              {{ $t("Account Details") }}
                            </vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </span>
                    </div>
                  </vs-td>

                </vs-tr>
              </template>
            </template>
          </vs-pagination-table>
        </div>
      </vs-col>
    </vs-row>
    <!-- <account-explanations></account-explanations> -->
  </div>
</template>

<script>
/* eslint-disable */

  import AccountExplanations from "@/views/pages/account/components/AccountExplantion";
  import CreateAccount from "@/views/pages/account/components/CreateAccount";
  import axios from "../../../axios";
  import vSelect from "vue-select";

  export default {
    components: {
      CreateAccount,
      AccountExplanations,
      vSelect,
    },
    data() {
      return {
        AccountId: "",
        detailPopup: false,
        popupData: {},
        //
        fileName: "",
        formats: ["xlsx", "csv", "txt"],
        cellAutoWidth: true,
        selectedFormat: "xlsx",
        headerTitle: [
          "Id",
          "Name Surname",
          "Email",
          "Phone",
          "Incomming",
          "Outcomming",
          "Balance",
          "Last Transaction",
        ],
        headerVal: [
          "id",
          "full_name",
          "email",
          "mobile",
          "income",
          "outcome",
          "balance",
          "last_transaction_date",
        ],
        activePrompt: false,
        total: [],
        hideData: false
      };
    },
    computed: {

      totalOutcome() {
        let total = this.total.map((x) => {
          let val = x.outcome.toString().replace(',', '').replace('.', '')
          return parseFloat(val)
        })
        if (total.length > 0) {
          return total.reduce((a, b) => a + b)
        }
        return 0
      },
      totalIncome() {
        let total = this.total.map((x) => {
          let val = x.income.toString().replace(',', '').replace('.', '')
          return parseFloat(val)
        })
        if (total.length > 0) {
          return total.reduce((a, b) => a + b)
        }
        return 0
      },
      accounts() {
        let data = this.$store.getters["account/accounts"];
        if (!this.hideData) {
          return data.filter(function (item) {
            return (parseInt(item.balance) !== 0)
          })
        }
        return data
      }

    },

    methods: {
      totalAccounts() {
        axios.get("/accounts?nopaginate=true").then((response) => {
          this.total = response.data.accounts
        })
      },
      exportToExcel() {
        axios.get("/accounts?nopaginate=true").then((response) => {
          import("../../../components/excel/ExportExcel").then((excel) => {
            const list = response.data.accounts;
            const data = this.formatJson(this.headerVal, list);
            excel.export_json_to_excel({
              header: this.headerTitle,
              data,
              filename: this.fileName,
              autoWidth: this.cellAutoWidth,
              bookType: this.selectedFormat || 'xlsx'
            });
            this.clearFields();
          });
        });
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            return v[j];
          })
        );
      },
      clearFields() {
        this.filename = "";
        this.cellAutoWidth = true;
        this.selectedFormat = "xlsx";
      },
      MakeCall(tel) {
        window.location.href = `tel://${tel}`;
      },
      SendEmail(email) {
        window.location.href = `mailto://${email}`;
      },

      DeleteAccount($val) {
        this.AccountId = $val;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: this.$t("Confirm Delete"),
          text: this.$t("Are you sure you want to delete this Account"),
          accept: this.acceptAlert,
          acceptText: this.$t("Delete"),
          cancelText: this.$t("Cancel"),
        });
      },
      acceptAlert() {
        this.$store.dispatch("account/DeleteAccount", this.AccountId);
        this.$store.dispatch("account/GetData")
      },
    },
    created() {
      this.totalAccounts()
    }
  };

</script>

<style lang="scss">
  .text-capital {
    text-transform: capitalize;
  }

  #data-list-list-view {
    .vs-con-table {

      /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        >span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }

            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }

</style>
